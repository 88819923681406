<template>
  <div>
    <v-row>
      <v-col
        :cols="2"
      >
        <h2>Awaiting Triage</h2>
      </v-col>
      <v-col
        :cols="2"
      >
        <h2>Triage Complete</h2>
      </v-col>
      <v-col
        :cols="2"
      >
        <h2>Triage Confirmed</h2>
      </v-col>
      <v-col
        :cols="2"
      >
        <h2>Triage Confirmed</h2>
      </v-col>
      <v-col
        :cols="2"
      >
        <h2>Work Underway</h2>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'KanbanDashboard',

  data () {
    return {

    }
  }
}
</script>
