<template>
  <div class="">
    <div>
      <v-icon x-small left>mdi-calendar</v-icon>
      <small><strong>Triage: </strong>{{ $momentDateNoYear(triageServiceRecord.created_at) }}</small>
    </div>
    <div>
      <v-icon x-small left>mdi-calendar</v-icon>
      <small><strong>TBO: </strong>{{ $momentDateNoYear(sundry.bike.planned_service_date) }}</small>
    </div>
    <div>
      <v-icon x-small left>mdi-wrench-outline</v-icon>
      <small>{{ sundry.bike.workshop_status_label }}</small>
    </div>
    <div>
      <v-icon x-small left>mdi-wrench-outline</v-icon>
      <small>{{ sundry.bike.job_type_label }}</small>
    </div>
  </div>
</template>

<script>
import HasServiceRecords from '@/mixins/HasServiceRecords'

export default {
  name: 'PartsToOrderTriageColumn',

  mixins: [ HasServiceRecords ],

  props: {
    sundry: {
      type: Object,
      required: true
    }
  },

  computed: {
    job () {
      return this.sundry.bike
    }
  }
}
</script>

<style lang="css" scoped>
</style>