<template>
  <div>
    <v-btn
      v-if="!sundry.received_at"
      color="primary"
      outlined
      x-small
      depressed
      @click.stop="dialog = true"
    >
      Update
    </v-btn>
    <div>
      <strong v-if="sundry.estimated_delivery_date" class="ml-1">
        <small>{{ $momentDateNoYear(sundry.estimated_delivery_date) }}</small>
      </strong>
    </div>

    <PartEstimatedDeliveryDialog
      :dialog.sync="dialog"
      :sundry="sundry"
    />
  </div>
</template>

<script>
import PartEstimatedDeliveryDialog from '@/components/sundries/PartEstimatedDeliveryDialog'

export default {
  name: 'PartsToOrderDeliveryColumn',

  props: {
    sundry: {
      type: Object,
      required: true
    }
  },

  components: {
    PartEstimatedDeliveryDialog
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>