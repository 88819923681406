<template>
  <v-row>
    <v-col
      class="py-0"
      cols="12"
      sm="4"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <DriverManifestSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
import DriverManifestSummary from '@/components/dashboard/DriverManifestSummary'
import IsDashboard from '@/mixins/IsDashboard'

export default {
  name: 'DriverDashboard',

  components: {
    DriverManifestSummary
  },

  mixins: [ IsDashboard ]
}
</script>
