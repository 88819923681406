<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Part Received</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <div v-if="numberOfOtherSundries === 0">
              <TboMetricsSummary
                title="Workshop TBOs"
                :end="$momentDateFormat($moment().add(14, 'days'))"
                :exclude-workshop-status="['STATUS_CANCELLED', 'STATUS_DISPATCHED', 'STATUS_TRIAGE_NO_CONTACT']"
              />

              <v-divider class="mt-6" />

              <v-row class="mt-2">
                <v-col :cols="12" :md="6">
                  <v-dialog
                    ref="tboDate"
                    v-model="dateModal"
                    :return-value.sync="form.planned_service_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="form.planned_service_date ? $moment(form.planned_service_date).format('ddd Do MMM') : null"
                        class="mt-4"
                        label="TBO Date"
                        outlined
                        dense
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      scrollable
                      v-model="form.planned_service_date"
                      :min="$moment().format('YYYY-MM-DD')"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateModal = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.tboDate.save(form.planned_service_date)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col :cols="12" :md="6">
                  <v-dialog
                    ref="returnDate"
                    v-model="returnDateModal"
                    :return-value.sync="form.rearranged_return_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="$moment(form.rearranged_return_date).format('ddd Do MMM')"
                        :rules="[v => !!v || 'Return date is required']"
                        class="mt-4"
                        label="Return Date"
                        readonly
                        outlined
                        dense
                        required
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      scrollable
                      v-model="form.rearranged_return_date"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="returnDateModal = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.returnDate.save(form.rearranged_return_date)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </div>

            <div v-else>
              <div v-if="numberOfOtherSundries > 0">
                <v-alert dense text outlined type="info">
                  {{ numberOfOtherSundries }} part{{numberOfOtherSundries > 1 ? 's' : ''}} still to be received for this job. <strong>The job status will remain 'Awaiting Parts' until these have been received.</strong>
                </v-alert>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Ordered
                        </th>
                        <th class="text-left">
                          Received
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="otherSundry in sundries">
                        <tr
                          v-if="otherSundry.id !== sundry.id"
                          :key="otherSundry.id"
                        >
                          <td>{{ otherSundry.trimmed_name }}</td>
                          <td>{{ otherSundry.ordered_at ? $momentDate(otherSundry.ordered_at) : '-' }}</td>
                          <td>{{ otherSundry.received_at ? $momentDate(otherSundry.received_at) : '-' }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>

            <v-row class="mt-4">
              <v-col :cols="12" :sm="6">
                <v-checkbox
                  v-model="form.send_received_notification"
                  :label="numberOfOtherSundries > 0 ? 'Send Part Notification?' : 'Send Part & Return Date Notifications?'"
                />
              </v-col>
            </v-row>

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" :loading="loading" text @click="updateSundry">Mark Part Received <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar success ref="successBar">
      Part marked as received successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue marking the part as received.
    </SnackBar>
  </div>
</template>

<script>
import HasForm from '@/mixins/HasForm'
import HasDialog from '@/mixins/HasDialog'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'
import TboMetricsSummary from '@/components/dashboard/TboMetricsSummary'

export default {
  name: 'PartReceivedDialog',

  mixins: [ HasForm, HasDialog ],

  props: {
    sundry: {
      required: true,
      type: Object
    }
  },

  components: {
    FormErrors,
    SnackBar,
    TboMetricsSummary
  },

  data () {
    return {
      loading: false,
      dateModal: false,
      returnDateModal: false,
      sundries: [],
      sundryError: {}
    }
  },

  watch: {
    dialog (dialog) {
      this.form = {
        ...this.form,
        ...this.sundry
      }

      if (this.sundry.estimated_delivery_date) {
        this.$set(this.form, 'estimated_delivery_date', this.$moment(this.sundry.estimated_delivery_date).format('YYYY-MM-DD'))
      }

      if (dialog) {
        this.getAllSundries()

        this.form = {
          ...this.form,
          ...{
            send_received_notification: true,
            planned_service_date: this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
            rearranged_return_date: this.$moment(new Date()).add(2, 'days').format('YYYY-MM-DD')
          }
        }
      } else {
        this.sundries = []
      }
    }
  },

  computed: {
    numberOfOtherSundries () {
      if (this.sundries) {
        return this.sundries.length - 1        
      }

      return -1
    }
  },

  methods: {
    getAllSundries () {
      this.loading = true
      
      this.$api.get({
        path: `bookings/${this.sundry.bike.booking_id}/sundries`,
        params: {
          noDeclined: true,
          noEstimates: true,
          noInStockOrArrived: true,
          bikeId: this.sundry.bike.id
        }
      })
        .then(({ data: sundries }) => {
          this.sundries = sundries
        })
        .catch(error => {
          this.sundryError = error
        })
        .finally(() => { this.loading = false })
    },

    updateSundry () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('put', {
            path: `bookings/${this.sundry.bike.booking_id}/sundries/${this.sundry.id}/received`,
            object: this.form
          })
          .then(() => {
            Promise.all([
              this.updateTboDate(),
              this.updateReturnDate(),
              this.updateWorkshopStatus()
            ])
              .then(() => {
                this.loading = false
                this.$refs.successBar.open()
                this.closeDialog()
                this.$emit('received')
              })
              .catch(error => {
                this.loading = false
                this.$refs.formErrors.setErrors(error)
                this.$refs.errorBar.open()
              })
          })
          .catch(error => {
            this.loading = false
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
      }
    },

    updateTboDate () {
      return new Promise((resolve, reject) => {
        if (this.numberOfOtherSundries > 0) {
          resolve()
        } else {
          this.$api.persist('put', {
            path: `bookings/${this.sundry.bike.booking_id}/bikes/${this.sundry.bike.id}/dates/tbo`,
            object: {
              planned_service_date: this.form.planned_service_date,
              reschedule_reason: 'REASON_AWAITING_PARTS',
              reschedule_notes: 'All parts arrived.',
            }
          })
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },

    updateReturnDate () {
      return new Promise((resolve, reject) => {
        if (this.numberOfOtherSundries > 0) {
          resolve()
        } else {
          this.$api.persist('put', {
            path: `bookings/${this.sundry.bike.booking_id}/bikes/${this.sundry.bike.id}/dates/cr`,
            object: {
              rearranged_return_date: this.form.rearranged_return_date,
              send_return_collection_notification: this.form.send_received_notification,
              reschedule_reason: 'REASON_AWAITING_PARTS',
              reschedule_notes: 'All parts arrived.',
            }
          })
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },

    updateWorkshopStatus () {
      return new Promise((resolve, reject) => {
        if (this.numberOfOtherSundries > 0) {
          resolve()
        } else {
          this.$api.persist('put', {
            path: `bookings/${this.sundry.bike.booking_id}/bikes/${this.sundry.bike.id}`,
            object: {
              ...this.sundry.bike,
              ...{
                workshop_status: 'STATUS_PARTS_ARRIVED'
              }
            }
          })
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>