<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Order Part - {{ sundry.name }}</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col :cols="12" :sm="6">
                <v-text-field
                  v-model="form.order_po"
                  :rules="[v => !!v || 'Order po is required']"
                  hint="The order number provided by the supplier."
                  label="Order Reference"
                  required
                  outlined
                  autofocus
                  dense
                />
              </v-col>
              <v-col :cols="12" :sm="6">
                <v-dialog
                  ref="expectedDeliveryDate"
                  v-model="expectedDeliveryDateDialog"
                  :return-value.sync="form.estimated_delivery_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :rules="[v => !!v || 'Expected delivery date is required']"
                      :value="form.estimated_delivery_date ? $moment(form.estimated_delivery_date).format('ddd Do MMM') : null"
                      label="Expected Delivery Date"
                      required
                      outlined
                      dense
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.estimated_delivery_date"
                    :min="$moment().format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="expectedDeliveryDateDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.expectedDeliveryDate.save(form.estimated_delivery_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12">
                <ApiAutoCompleteField
                  v-model="form.supplier_id"
                  :rules="[v => !!v || 'Supplier is required']"
                  :current="sundry.stock_product_data ? sundry.stock_product_data.supplier : null"
                  required
                  label="Supplier"
                  :url="`${$inventory.url}/api/suppliers`"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-4" />
            <v-row>
              <v-col :cols="12" :sm="6">
                <v-checkbox
                  v-model="form.send_expected_delivery_date_notification"
                  label="Send Expected Delivery Notification?"
                />
              </v-col>
            </v-row>
            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" :loading="loading" text @click="updateSundry">Mark Part Ordered <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar success ref="successBar">
      Part marked as ordered successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue marking the part as ordered.
    </SnackBar>
  </div>
</template>

<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import FormErrors from '@/components/FormErrors'
import ApiAutoCompleteField from '@/components/ApiAutoCompleteField'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'PartOrderedDialog',

  mixins: [ HasDialog, HasForm ],

  props: {
    sundry: {
      required: true,
      type: Object
    }
  },

  components: {
    FormErrors,
    ApiAutoCompleteField,
    SnackBar
  },

  data () {
    return {
      loading: false,
      expectedDeliveryDateDialog: false
    }
  },

  watch: {
    dialog (dialog) {
      this.form = {
        ...this.form,
        ...this.sundry
      }
      if (dialog) {
        this.form = {
          ...this.form,
          ...{
            send_expected_delivery_date_notification: true
          }
        }
      }
      if (this.sundry.estimated_delivery_date) {
        this.$set(this.form, 'estimated_delivery_date', this.$moment(this.sundry.estimated_delivery_date).format('YYYY-MM-DD'))
      }
      if (!this.sundry.order_po) {
        this.$set(this.form, 'order_po', this.makePO(8))
      }
    }
  },

  methods: {
    updateSundry () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('put', {
            path: `bookings/${this.sundry.bike.booking_id}/sundries/${this.sundry.id}/ordered`,
            object: {
              supplier_id: this.form.supplier_id?.id || (this.sundry.stock_product_data ? this.sundry.stock_product_data?.supplier?.id : null),
              supplier_name: this.form.supplier_id?.id || (this.sundry.stock_product_data ? this.sundry.stock_product_data?.supplier?.name : null),
              estimated_delivery_date: this.form.estimated_delivery_date,
              send_expected_delivery_date_notification: this.form.send_expected_delivery_date_notification || false,
              order_po: this.form.order_po
            }
          })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    },

    makePO(length) {
      var result = []
      var characters = 'abcde0123456789'
      var charactersLength = characters.length
      for ( var i = 0; i < length; i++ ) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        )
      }
      return 'hbpo' + result.join('')
    }
  }
}
</script>

<style lang="css" scoped>
</style>