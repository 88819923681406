var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"text-h4 text-lg-h2 green--text"},[_vm._v("Approved")]),_c('h2',{staticClass:"text-h4 text-lg-h2 green--text font-weight-medium"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_revenue_wip_total)))])]),_c('div',{staticClass:"d-flex justify-space-between mt-2"},[_c('h3',{staticClass:"text-h4 text-lg-h2 text--lighten-3 orange--text"},[_vm._v("Awaiting QC")]),_c('h2',{staticClass:"text-h4 text-lg-h2 text--lighten-3 orange--text font-weight-medium"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_revenue_awaiting_qc_total)))])]),_c('div',{staticClass:"d-flex justify-space-between mt-2"},[_c('h3',{staticClass:"text-h4 text-lg-h2 orange--text"},[_vm._v("QC Passed")]),_c('h2',{staticClass:"text-h4 text-lg-h2 orange--text font-weight-medium"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_revenue_completed_total)))])]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-space-between mt-2"},[_c('h3',{staticClass:"text-h4 text-lg-h2"},[_vm._v("Board Total")]),_c('h2',{staticClass:"text-h4 text-lg-h2 font-weight-medium"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_revenue_total)))])]),_c('h3',{staticClass:"mt-4"},[_vm._v(" Revenue Earned Today "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Total revenue earned today (Excl VAT). This figure doesn't include complaints. Bicycles that have been have been QC passed and have a TBO date equal to today will be included.")])])],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-h5 text-md-h4 primary--text"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_average_revenue_per_bike)))]),_c('h3',[_vm._v(" ARPB "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Average Revenue Per Bike - Based on "+_vm._s(_vm.metrics.today_bikes_total)+" bikes with a TBO date of today (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. This figure includes the upfront service cost as well as any sundries that have been approved by the customer.")])])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-h5 text-md-h4 primary--text"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_average_upfront_per_bike)))]),_c('h3',[_vm._v(" AURPB "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Average Upfront Revenue Per Bike - Based on "+_vm._s(_vm.metrics.today_bikes_total)+" bikes with a TBO date of today (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. This figure only includes the upfront service cost paid by the customer.")])])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-h5 text-md-h4 primary--text"},[_vm._v(_vm._s(_vm.$currency(_vm.metrics.today_average_sundries_per_bike)))]),_c('h3',[_vm._v(" ASRPB "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Average Sundries Revenue Per Bike - Based on "+_vm._s(_vm.metrics.today_bikes_total)+" bikes with a TBO date of today (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. This figure only includes the sundries approved by the customer.")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }