<template>
  <div>
    <h2 class="primary--text">Welcome to havebike Atlas!</h2>
    <p class="mt-3">
      {{ $auth.user.first_name }}, welcome to havebike Atlas. Your your current role is: <strong v-if="$auth.user">{{ $auth.user.roles[0] ? $auth.user.roles[0].name : '' }}</strong>
    </p>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'UserWelcomeSummary'
}
</script>
