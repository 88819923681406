<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="text-h3 text-md-h1 primary--text">{{ metrics.collections }}</h2>
        <h3>Collections Today</h3>
      </v-col>
      <v-col>
        <h2 class="text-h3 text-md-h1 primary--text">{{ metrics.returns }}</h2>
        <h3>Returns Today</h3>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'TodayCRMetricsSummary',

  data () {
    return {
      metrics: {}
    }
  },

  mounted () {
    this.getMetrics()
  },

  methods: {
    getMetrics () {
      this.$api.get({
        path: 'bookings/metrics/today-cr'
      })
      .then(({ data: metrics }) => {
        this.metrics = metrics
      })
    }
  }
}
</script>
