<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <h1 class="text-h3 text-md-h1 primary--text">{{ metrics.total }}</h1>
        <h3>Total Bookings Today</h3>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="4" md="6">
            <h2 class="text-h5 text-md-h4 primary--text">{{ metrics.regular + metrics.regular_electric }}</h2>
            <h3>Regular</h3>
          </v-col>
          <v-col cols="4" md="6">
            <h2 class="text-h5 text-md-h4 primary--text">{{ metrics.deluxe + metrics.deluxe_electric }}</h2>
            <h3>Deluxe</h3>
          </v-col>
          <v-col cols="4" md="6">
            <h2 class="text-h5 text-md-h4 primary--text">{{ metrics.super_deluxe + metrics.super_deluxe_electric }}</h2>
            <h3>S Deluxe</h3>
          </v-col>
          <v-col cols="6">
            <h2 class="text-h5 text-md-h4 primary--text">{{ metrics.bct + metrics.bct_electric }}</h2>
            <h3>BCT</h3>
          </v-col>
          <v-col cols="6">
            <h2 class="text-h5 text-md-h4 primary--text">{{ metrics.halfords_silver + metrics.halfords_silver_electric }}</h2>
            <h3>Silver</h3>
          </v-col>
          <v-col cols="6">
            <h2 class="text-h5 text-md-h4 primary--text">{{ metrics.halfords_gold + metrics.halfords_gold_electric }}</h2>
            <h3>Gold</h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'TodayBookingMetricsSummary',

  data () {
    return {
      metrics: {}
    }
  },

  mounted () {
    this.getMetrics()
  },

  methods: {
    getMetrics () {
      this.$api.get({
        path: 'bookings/metrics/today-totals'
      })
      .then(({ data: metrics }) => {
        this.metrics = metrics
      })
    }
  }
}
</script>
