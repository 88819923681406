<template>
  <v-row>
    <v-col cols="12" md="6">
      <div class="d-flex justify-space-between">
        <h3 class="text-h4 text-lg-h2 green--text">Approved</h3>
        <h2 class="text-h4 text-lg-h2 green--text font-weight-medium">{{ $currency(metrics.today_revenue_wip_total) }}</h2>
      </div>

      <div class="d-flex justify-space-between mt-2">
        <h3 class="text-h4 text-lg-h2 text--lighten-3 orange--text">Awaiting QC</h3>
        <h2 class="text-h4 text-lg-h2 text--lighten-3 orange--text font-weight-medium">{{ $currency(metrics.today_revenue_awaiting_qc_total) }}</h2>
      </div>

      <div class="d-flex justify-space-between mt-2">
        <h3 class="text-h4 text-lg-h2 orange--text">QC Passed</h3>
        <h2 class="text-h4 text-lg-h2 orange--text font-weight-medium">{{ $currency(metrics.today_revenue_completed_total) }}</h2>
      </div>

      <v-divider class="my-3" />

      <div class="d-flex justify-space-between mt-2">
        <h3 class="text-h4 text-lg-h2">Board Total</h3>
        <h2 class="text-h4 text-lg-h2 font-weight-medium">{{ $currency(metrics.today_revenue_total) }}</h2>
      </div>

      <h3 class="mt-4">
        Revenue Earned Today
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="grey"
              small
              dense
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Total revenue earned today (Excl VAT). This figure doesn't include complaints. Bicycles that have been have been QC passed and have a TBO date equal to today will be included.</span>
        </v-tooltip>
      </h3>
    </v-col>
    <v-col>
      <v-row>
        <v-col cols="6">
          <h1 class="text-h5 text-md-h4 primary--text">{{ $currency(metrics.today_average_revenue_per_bike) }}</h1>
          <h3>
            ARPB
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="grey"
                  small
                  dense
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Average Revenue Per Bike - Based on {{ metrics.today_bikes_total }} bikes with a TBO date of today (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. This figure includes the upfront service cost as well as any sundries that have been approved by the customer.</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="6">
          <h1 class="text-h5 text-md-h4 primary--text">{{ $currency(metrics.today_average_upfront_per_bike) }}</h1>
          <h3>
            AURPB
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="grey"
                  small
                  dense
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Average Upfront Revenue Per Bike - Based on {{ metrics.today_bikes_total }} bikes with a TBO date of today (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. This figure only includes the upfront service cost paid by the customer.</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col cols="6">
          <h1 class="text-h5 text-md-h4 primary--text">{{ $currency(metrics.today_average_sundries_per_bike) }}</h1>
          <h3>
            ASRPB
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="grey"
                  small
                  dense
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Average Sundries Revenue Per Bike - Based on {{ metrics.today_bikes_total }} bikes with a TBO date of today (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. This figure only includes the sundries approved by the customer.</span>
            </v-tooltip>
          </h3>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
export default {
  name: 'IncomeSummary',

  data () {
    return {
      metrics: {}
    }
  },

  mounted () {
    this.getMetrics()
  },

  methods: {
    getMetrics () {
      this.$api.get({
        path: 'finance/metrics/revenue'
      })
      .then(({ data: metrics }) => {
        this.metrics = metrics
      })
    }
  }
}
</script>
