<template>
  <tr :class="{
    'red lighten-4 red--text': job.workshop_status === 'STATUS_TRIAGE_UNABLE_TO_CONTACT',
    'red lighten-5 red--text': job.workshop_status === 'STATUS_CUSTOMER_CONSIDERING',
    'blue lighten-3 blue--text': isWorkUnderway,
    'green lighten-2': job.workshop_status === 'STATUS_TRIAGE_CONFIRMED' || job.workshop_status === 'STATUS_PARTS_ARRIVED',
    'orange lighten-4 orange--text': job.workshop_status === 'STATUS_AWAITING_QC',
    'orange lighten-2': job.workshop_status === 'STATUS_QC_PASSED',
    'orange': job.workshop_status === 'STATUS_DISPATCHED',
  }">
    <td>
      <v-chip
        :to="{ name: 'bookings.show', params: { bookingId: job.booking_id } }"
        :class="{'red lighten-3': job.job_type === 'JOB_TYPE_C&R_COMPLAINT'}"
        x-small
      >
        <v-icon v-if="job.job_type === 'JOB_TYPE_C&R_COMPLAINT'" left x-small color="red darken-2">mdi-exclamation-thick</v-icon>
        {{ job.ref || ' - ' }}
        <span v-if="job.asset" class="ml-1">/ {{ job.asset.number }}</span>
      </v-chip>
    </td>
    <td class="font-weight-medium">
      {{ job.booking.customer_name }}
      <small v-if="fleet && job.booking.customer.account">
        <span v-if="job.booking.customer.account.parent">({{ job.booking.customer.account.name }}) {{ job.booking.customer.account.parent.name }}</span>
        <span v-else>{{ job.booking.customer.account.name }}</span>
      </small>
    </td>
    <td class="font-weight-medium">
      <span class="font-weight-black">{{ job.location_prefix_label }}</span>{{ job.location }}
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="triageServiceRecord" :job="job" />
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="cleanServiceRecord" :job="job" />      
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="triageConfirmationServiceRecord" :job="job" />      
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="serviceRecord" :job="job" :timer="job.workshop_status === 'STATUS_WORK_UNDERWAY'" />
    </td>
    <td>
      <WorkshopDashboardServiceRecordCol :service-record="qualityControlServiceRecord" :job="job" />
    </td>
    <td>
      <v-dialog
        v-if="job.booking.workshop_notes.length > 0"
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="error"
            x-small
            depressed
            v-bind="attrs"
            v-on="on"
          >
            {{ job.booking.workshop_notes.length }} Note{{ job.booking.workshop_notes.length > 1 ? 's' : '' }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="headline grey lighten-2">
            Notes For {{ job.ref }}
          </v-card-title>

          <v-card-text class="d-flex flex-column pt-4">
            <div v-for="note in job.booking.workshop_notes" :key="note.id" class="w-full my-2">
              <NoteBox :data="note">
                {{ note.content }}
                <template #info>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="grey"
                        small
                        dense
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      {{ $momentDateTimeHuman(note.created_at) }} by {{ note.created_by.name }}
                      <v-avatar size="20" color="primary">
                        <img
                          :src="note.created_by.profile_img_url"
                          :alt="note.created_by.name">
                      </v-avatar>
                    </span>
                  </v-tooltip>
                </template>
              </NoteBox>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </td>
    <td class="font-weight-medium">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-chip
            v-if="job.manafacturer"
            v-on="on"
            x-small
            class="mr-1"
          >
            {{ format(job.manafacturer) }}
          </v-chip>
        </template>
        <span>Manafacturer</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-chip
            v-if="job.model"
            v-on="on"
            x-small
            class="mr-1"
          >
            {{ format(job.model) }}
          </v-chip>
        </template>
        <span>Model</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-chip
            v-if="job.colour"
            v-on="on"
            x-small
            class="mr-1"
          >
            {{ format(job.colour) }}
          </v-chip>
        </template>
        <span>Colour</span>
      </v-tooltip>
    </td>
    <td class="font-weight-medium">
      <span v-if="job.is_complaint">
        Complaint: <strong>{{ job.complaint_category_label }}</strong>
      </span>
      <span v-else>{{ job.service.name }}</span>
    </td>
    <td class="font-weight-medium">
      <span v-if="job.rearranged_return_date" class="font-weight-black">{{ $momentDate(job.rearranged_return_date) }}</span>
      <span v-else>{{ $momentDate(job.booking.return_date) }}</span>
    </td>
    <td v-if="!fleet" class="font-weight-medium">{{ $currency(job.sundries_total) }} / {{ $currency(job.total + job.sundries_total) }}</td>
    <td>
      <v-chip
        class="font-weight-medium"
        x-small
        :color="
          job.workshop_status === 'STATUS_CANCELLED' ? 'red' :
          job.workshop_status === 'STATUS_QC_PASSED' ? 'orange' :
          ''
        "
      >
        {{ job.workshop_status_label }}
      </v-chip>
    </td>
  </tr>
</template>

<script>
import WorkshopDashboardServiceRecordCol from '@/components/dashboard/WorkshopDashboardServiceRecordCol'
import HasServiceRecords from '@/mixins/HasServiceRecords'
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'WorkshopDashboardRow',

  mixins: [ HasServiceRecords ],

  props: {
    fleet: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    job: {
      type: Object,
      required: true
    }
  },

  components: {
    NoteBox,
    WorkshopDashboardServiceRecordCol
  },

  data () {
    return {
      dialog: false
    }
  },

  computed: {
    isWorkUnderway () {
      return this.job.workshop_status === 'STATUS_WORK_UNDERWAY' || this.job.workshop_status === 'STATUS_QC_STARTED' || this.job.workshop_status === 'STATUS_TRIAGE_STARTED'
    }
  },

  methods: {
    format(str) {
      if (str) {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        )
      }
      return str
    }
  }
}
</script>
