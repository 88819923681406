<template>
  <div>
      <v-row class="mb-2">
        <v-col class="pt-0">
          <h1 class="primary--text display-3">{{ totalJobs }}</h1>
          <h3>Total Jobs</h3>
        </v-col>
        <v-col class="pt-0">
          <h1 class="primary--text display-3">{{ totalFleetJobs }}</h1>
          <h3>Total Fleet Jobs</h3>
        </v-col>
        <v-col class="pt-0">
          <h1 class="primary--text display-3">{{ totalCRJobs }}</h1>
          <h3>Total C&R Jobs</h3>
        </v-col>
        <v-col class="pt-0">
          <h1 class="primary--text display-3">{{ totalComplaintJobs }}</h1>
          <h3>Total C&R Complaints</h3>
        </v-col>
      </v-row>
      <v-divider class="mb-4" />
      <div class="d-flex justify-content-between align-items-center w-full pb-2">
        <v-btn
          outlined
          dark
          small
          depressed
          color="primary"
          :loading="loading"
          @click="getBikes"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <div>
          <v-btn
            outlined
            dark
            small
            depressed
            color="primary"
            class="mr-2"
            :loading="loading"
            @click="day = day - 1; getBikes()"
          >
            <v-icon>mdi-arrow-left-drop-circle</v-icon>
          </v-btn>
          <v-btn
            outlined
            dark
            small
            depressed
            color="primary"
            :loading="loading"
            @click="day = day + 1; getBikes()"
          >
            <v-icon>mdi-arrow-right-drop-circle</v-icon>
          </v-btn>
        </div>
      </div>
    <v-simple-table
      fixed-header
      dense
      height="1000px"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Ref</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Loc</th>
            <th class="text-left">Triage</th>
            <th class="text-left">Cleaned</th>
            <th class="text-left">Agreed</th>
            <th class="text-left">Comp</th>
            <th class="text-left">QC'd</th>
            <th class="text-left">Notes</th>
            <th class="text-left">Bike</th>
            <th class="text-left">Service</th>
            <th class="text-left">Return</th>
            <th class="text-left">Total</th>
            <th class="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          <WorkshopDashboardRow
            v-for="job in jobs"
            :key="job.id"
            v-bind="{ job }"
          />
        </tbody>
      </template>
    </v-simple-table>

    <SnackBar success ref="successBar">
      Jobs updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import IsDashboard from '@/mixins/IsDashboard'
import WorkshopDashboardRow from '@/components/dashboard/WorkshopDashboardRow'
import SnackBar from '@/components/SnackBar'
import filter from 'lodash.filter'

export default {
  name: 'WorkshopDashboard',

  mixins: [ IsDashboard ],

  components: {
    WorkshopDashboardRow,
    SnackBar
  },

  data () {
    return {
      loading: false,
      jobs: [],
      error: {},
      day: 1
    }
  },

  mounted () {
    this.getBikes(false)
    if (window.Echo) {
      window.Echo.private(`booking-bikes-returns-${this.$moment().add(1, 'd').format('YYYY-MM-DD')}`)
        .listen('BookingBikeUpdated', () => {
          this.getBikes(false)
        })
        .listen('BookingBikeCreated', () => {
          this.getBikes(false)
        })
    }
  },

  computed: {
    totalJobs () {
      return this.jobs.length
    },

    totalFleetJobs () {
      return filter(this.jobs, { 'job_type': 'JOB_TYPE_FLEET' }).length
    },

    totalCRJobs () {
      return filter(this.jobs, { 'job_type': 'JOB_TYPE_C&R' }).length
    },

    totalComplaintJobs () {
      return filter(this.jobs, { 'job_type': 'JOB_TYPE_C&R_COMPLAINT' }).length
    }
  },

  methods: {
    getBikes (showSuccess = true) {
      this.loading = true
      this.error = {}
      this.$api.get({
        path: 'jobs',
        params: {
          limit: 1000,
          orderBy: 'job_number',
          exclude: ['JOB_TYPE_DOORSTEP'],
          ascending: true,
          return_date_on: this.$moment().add(this.day, 'd').format('YYYY-MM-DD'),
          with: ['serviceRecords.startedBy', 'serviceRecords.endedBy', 'booking.notes.createdBy', 'asset', 'service']
        }
      })
        .then(({ data: bikes }) => {
          this.jobs = bikes
          if (showSuccess) {
            this.$refs.successBar.open()
          }
        })
        .catch(error => {
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
