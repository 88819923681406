<template>
  <v-row>
    <v-col
      class="py-0 mb-6"
      cols="12"
      sm="6"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <MonthCollectionMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      class="py-0 mb-6"
      cols="12"
      sm="6"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <MonthReturnMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
import MonthCollectionMetricsSummary from '@/components/dashboard/MonthCollectionMetricsSummary'
import MonthReturnMetricsSummary from '@/components/dashboard/MonthReturnMetricsSummary'
import IsDashboard from '@/mixins/IsDashboard'

export default {
  name: 'OperationsDashboard',

  components: {
    MonthCollectionMetricsSummary,
    MonthReturnMetricsSummary
  },

  mixins: [ IsDashboard ]
}
</script>
