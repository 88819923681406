<template>
  <v-row>
    <v-col cols="12" md="6">
      <h1 class="text-h3 text-md-h1 primary--text">{{ $currency(metrics.mtd_revenue_total) }}</h1>
      <h3>
        MTD Revenue
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="grey"
              small
              dense
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Total revenue earned to date this month (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. Bicycles that have been have been QC passed and have a TBO date equal or before today will be included.</span>
        </v-tooltip>
      </h3>
    </v-col>
    <v-col>
      <v-row>
        <v-col>
          <h1 class="text-h5 text-md-h4 primary--text">{{ metrics.mtd_bikes_total }}</h1>
          <h3>
            MTD Bikes
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="grey"
                  small
                  dense
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Total number of bikes worked on to date this month. This figure only includes C&R bikes and doesn't include complaints. Bicycles that have been have been QC passed and have a TBO date equal or before today will be included.</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <h1 class="text-h5 text-md-h4 primary--text">{{ $currency(metrics.mtd_revenue_total / metrics.mtd_bikes_total) }}</h1>
          <h3>
            MTD ARPB
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="grey"
                  small
                  dense
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Average Revenue Per Bike - Based on the number of bikes worked on to date this month (Excl VAT). This figure only includes C&R bikes and doesn't include complaints. Bicycles that have been have been QC passed and have a TBO date equal or before today will be included.</span>
            </v-tooltip>
          </h3>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
export default {
  name: 'MtdSummary',

  data () {
    return {
      metrics: {}
    }
  },

  mounted () {
    this.getMetrics()
  },

  methods: {
    getMetrics () {
      this.$api.get({
        path: 'finance/metrics/mtd-numbers'
      })
      .then(({ data: metrics }) => {
        this.metrics = metrics
      })
    }
  }
}
</script>
