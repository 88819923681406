<template>
  <div>
      <v-row class="mb-2">
        <v-col class="pt-0">
          <h1 class="primary--text display-3">{{ $moment().add(day, 'd').format('ddd Do MMM YYYY') }} Productivity</h1>
        </v-col>
      </v-row>
      <div class="d-flex justify-content-between align-items-center w-full pb-2">
        <v-btn
          outlined
          dark
          small
          depressed
          color="primary"
          :loading="loading"
          @click="getUsers"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <div>
          <v-btn
            outlined
            dark
            small
            depressed
            color="primary"
            class="mr-2"
            :loading="loading"
            @click="day = day - 1"
          >
            <v-icon>mdi-arrow-left-drop-circle</v-icon>
          </v-btn>
          <v-btn
            outlined
            dark
            small
            depressed
            color="primary"
            :loading="loading"
            @click="day = day + 1"
          >
            <v-icon>mdi-arrow-right-drop-circle</v-icon>
          </v-btn>
        </div>
      </div>
    <v-simple-table
      v-if="Object.keys(serviceRecordProductivity).length > 1"
      fixed-header
      dense
      height="1000px"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">User</th>
            <th class="text-left">Triages</th>
            <th class="text-left">BCT</th>
            <th class="text-left">Bike Build</th>
            <th class="text-left">Regular</th>
            <th class="text-left">Deluxe</th>
            <th class="text-left">Super Deluxe</th>
            <th class="text-left">Silver</th>
            <th class="text-left">Gold</th>
            <th class="text-left">Other</th>
            <th class="text-left">QC's</th>
            <th class="text-left">Total Points</th>
            <th class="text-left">Total Revenue</th>
            <th class="text-left">MTD Average</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="user in orderedUsers">
            <ProductivityDashboardRow
              v-if="serviceRecordProductivity[user.id] && servicesProductivity[user.id] && mtdProductivity[user.id]"
              :key="user.id"
              :user="user"
              :service-record-productivity="serviceRecordProductivity[user.id][$moment().add(day, 'd').format('DD-MM-YYYY')]"
              :services-productivity="servicesProductivity[user.id][$moment().add(day, 'd').format('DD-MM-YYYY')]"
              :mtd-productivity="mtdProductivity[user.id]"
            />
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <SnackBar success ref="successBar">
      users updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ error.message }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import IsDashboard from '@/mixins/IsDashboard'
import find from 'lodash.find'
import orderBy from 'lodash.orderby'
import ProductivityDashboardRow from '@/components/dashboard/ProductivityDashboardRow'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'ProductivityDashboard',

  mixins: [ IsDashboard ],

  components: {
    ProductivityDashboardRow,
    SnackBar
  },

  data () {
    return {
      serviceRecordProductivity: {},
      servicesProductivity: {},
      mtdProductivity: {},
      loading: false,
      users: [],
      error: {},
      day: 0
    }
  },

  mounted () {
    this.getUsers(false)
    if (window.Echo) {
      window.Echo.private(`booking-bikes-returns-${this.$moment().add(1, 'd').format('YYYY-MM-DD')}`)
        .listen('BookingBikeUpdated', () => {
          this.getUsers(false)
        })
        .listen('BookingBikeCreated', () => {
          this.getUsers(false)
        })
    }
  },

  computed: {
    orderedUsers () {
      var users = []
      Object.keys(this.serviceRecordProductivity).forEach(userId => {
        var user = find(this.users, { id: userId })
        var points = 0
        if (user) {
          if (this.serviceRecordProductivity[userId]) {
            points = this.serviceRecordProductivity[userId][this.$moment().add(this.day, 'd').format('DD-MM-YYYY')].TOTAL_POINTS.points
          }
          users.push({
            ...user,
            ...{ points: points }
          })
        }
      })
      return orderBy(users, 'points', 'desc')
    }
  },

  methods: {
    getProductivity () {
      this.$api.get({
        path: 'productivity/metrics/service-records'
      })
        .then(({ data: serviceRecordProductivity }) => {
          this.serviceRecordProductivity = serviceRecordProductivity
        })
    },

    getServicesProductivity () {
      this.$api.get({
        path: 'productivity/metrics/services'
      })
        .then(({ data: servicesProductivity }) => {
          this.servicesProductivity = servicesProductivity
        })
    },

    getMtdProductivity () {
      this.$api.get({
        path: 'productivity/metrics/mtd'
      })
        .then(({ data: mtdProductivity }) => {
          this.mtdProductivity = mtdProductivity
        })
    },

    getUsers (showSuccess = true) {
      this.loading = true
      this.error = {}
      this.$api.get({
        path: 'admin-users',
        params: {
          limit: 1000,
          role: 'Mechanic'
        }
      })
        .then(({ data: bikes }) => {
          this.users = bikes
          this.getProductivity()
          this.getServicesProductivity()
          this.getMtdProductivity()
          if (showSuccess) {
            this.$refs.successBar.open()
          }
        })
        .catch(error => {
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
