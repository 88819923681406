export default {
  computed: {
    chartData () {
      var datasets = []
      if (this.metrics.datasets) {
        var labels = [...Array(this.metrics.labels.length).keys()]
        this.metrics.datasets.forEach((dataset, key) => {
          var bgColors = []
          var borderColors = []
          labels.forEach(() => {
            bgColors.push(this.barBgColors[key])
            borderColors.push(this.barBorderColors[key])
          })
          datasets.push({
            ...{
              backgroundColor: bgColors,
              borderColor: borderColors,
              borderWidth: 1
            },
            ...dataset
          })
        })
      }
      return datasets
    },

    barBgColors () {
      return [
        'rgba(0, 160, 155, 0.3)',
        'rgb(255, 148, 28, 0.3)',
        'rgb(33, 150, 243, 0.3)',
        'rgb(233, 30, 99, 0.3)'
      ]
    },

    barBorderColors () {
      return [
        'rgba(0, 160, 155, 1)',
        'rgb(255, 148, 28, 1)',
        'rgb(33, 150, 243, 1)',
        'rgb(233, 30, 99, 1)'
      ]
    },

    chartConfig () {
      return {
        type: 'line',
        data: {
          labels: this.metrics.labels,
          datasets: this.chartData
        },
        options: {
          title: {
            display: true,
            text: this.title
          },
          legend: {
            labels: {
              filter: (item) => {
                return !item.text.includes('Total')
              }
            }
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: (tooltipItem, data) => {
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                if (data.datasets[tooltipItem.datasetIndex].format === 'currency') {
                  return this.$currency(value)
                }
                return value
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  }
}