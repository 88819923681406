<template>
  <div style="position: relative;">
    <canvas ref="monthReturns" />
  </div>
</template>
<script type="text/javascript">
import HasMetrics from '@/mixins/HasMetrics'
import HasTable from '@/mixins/HasTable'
import HasViewportCheck from '@/mixins/HasViewportCheck'
import Chart from 'chart.js'

export default {
  name: 'MonthReturnMetricsSummary',

  mixins: [ HasMetrics, HasTable, HasViewportCheck ],

  props: {
    next: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      title: this.next ? 'Returns Next Month' : 'Returns This Month'
    }
  },

  watch: {
    'viewport.isMobile': {
      handler () {
        this.resizeChart('monthReturns', '500px')
      }
    }
  },

  methods: {
    mountChart () {
      new Chart(
        this.$refs.monthReturns,
        this.chartConfig
      )
    },

    getMetrics () {
      this.$api.get({
        path: this.next ? 'bookings/metrics/returns-next-calendar-month' : 'bookings/metrics/returns-last-calendar-month'
      })
      .then(({ data: metrics }) => {
        this.metrics = metrics
        this.mountChart()
        this.resizeChart('monthReturns', '500px')
      })
    }
  }
}
</script>
