<template>
  <div>
    <h3>My Manifests For Today</h3>

    <DataTable
      :cols="cols"
      :request-params="{ with: ['vehicle'], driver_id: $auth.user.id, new: true, published: true }"
      :show-select="false"
      default-order-by="created_at"
      path="manifests"
    >
      <template v-slot:item.description="{ item: manifest }">
        <router-link :to="{ name: 'manifests.driver-view', params: { manifestId: manifest.id } }">
          {{ manifest.description || 'Manifest' }}
        </router-link>
      </template>
      <template v-slot:item.vehicle="{ item: manifest }">
        {{ manifest.vehicle.name }}
      </template>
      <template v-slot:item.on_date="{ item: manifest }">
        {{ $momentDate(manifest.on_date) }}
      </template>
    </DataTable>
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'

export default {
  name: 'DriverManifestSummary',

  components: {
    DataTable
  },

  data () {
    return {
      cols: [
        { text: 'Description', value: 'description' },
        { text: 'Vehicle', value: 'vehicle' },
        { text: 'Date', value: 'on_date' }
      ]
    }
  }
}
</script>
