<template>
  <div>
    <h3>Reports</h3>

    <div class="mt-3">
      <v-btn
        :loading="loading === 'today-returns'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('today-returns')"
      >
        1. Today's Returns
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
      <v-btn
        :loading="loading === 'tomorrow-returns'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('tomorrow-returns')"
      >
        2. Tomorrow's Returns
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
      <v-btn
        :loading="loading === 'next-week-returns'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('next-week-returns')"
      >
        3. Next Weeks's Returns
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
      <v-btn
        :loading="loading === 'mtd-returns'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('mtd-returns')"
      >
        4. MTD Returns
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>

      <v-divider class="mb-4 mt-3" />

      <v-btn
        :loading="loading === 'today-collections'"
        color="orange"
        depressed
        small
        outlined
        class="mr-2 mb-2"
        @click="getReport('today-collections')"
      >
        4. Today's Collections
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>

      <v-btn
        :loading="loading === 'tomorrow-collections'"
        color="orange"
        depressed
        small
        outlined
        class="mr-2 mb-2"
        @click="getReport('tomorrow-collections')"
      >
        5. Tomorrow's Collections
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>

      <v-btn
        :loading="loading === 'next-week-collections'"
        color="orange"
        depressed
        small
        outlined
        class="mr-2 mb-2"
        @click="getReport('next-week-collections')"
      >
        6. Next Week's Collections
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>

      <v-btn
        :loading="loading === 'future-collections'"
        color="orange"
        depressed
        small
        outlined
        class="mr-2 mb-2"
        @click="getReport('future-collections')"
      >
        7. Future Collections
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>

      <v-divider class="mb-4 mt-3" />

      <v-btn
        :loading="loading === 'logistics-failure-report'"
        color="red"
        depressed
        small
        outlined
        class="mr-2 mb-2"
        @click="getReport('logistics-failure-report')"
      >
        8. Logistics Failure Report
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
    </div>
    <SnackBar success ref="successBar">
      Report created successfully, download will start now!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ reportError }}
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import SnackBar from '@/components/SnackBar'

export default {
  name: 'ReportsSummary',

  components: {
    SnackBar
  },

  data () {
    return {
      loading: null,
      reportError: {},
      producedReports: {}
    }
  },

  methods: {
    getReport (type) {
      this.reportError = null
      this.loading = type
      this.$api.persist('post', {
        path: `reports/${type}`
      })
        .then(({ data: report }) => {
          this.checkReport(type, report)
        })
    },

    checkReport (type, report) {
      setTimeout(() => {
        this.$api.persist('post', {
          path: 'file-check',
          object: report
        })
          .then(({ data: file }) => {
            if (file.exists) {
              this.$refs.successBar.open()
              this.loading = false
              this.$set(this.producedReports, type, report)
              window.open(report.temp_url, report.name)
            } else {
              this.checkReport(type, report)
            }
          })
          .catch(() => {
            this.$refs.errorBar.open()
            this.reportError = 'An error was encounted when fetching the report file. Please try again or contact support. Error code: 404'
            this.loading = false
          })
      }, 3000)
    }
  }
}
</script>
