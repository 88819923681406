<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="12" :lg="2" class="mb-0">
        <v-select
          v-model="selectedDashboard"
          :items="dashboards"
          item-text="name"
          item-value="component"
          label="Dashboard"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <component
      v-if="dashboardComponent"
      :is="dashboardComponent"
      :key="1"
    />
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',

  data () {
    return {
      selectedDashboard: null
    }
  },

  computed: {
    dashboardComponent () {
      if (this.selectedDashboard) {
        return () => import(`@/components/dashboard/layouts/${this.selectedDashboard}.vue`)
      }
      return () => import(`@/components/dashboard/layouts/DefaultDashboard.vue`)
    },

    dashboards () {
      var dir = require.context('@/components/dashboard/layouts/', true, /\.vue$/)

      var dashboards = []

      dir.keys().forEach(file => {
        var componentName = file.split('.vue').shift().split('./').pop()
        var name = componentName.replace(/([A-Z])/g, ' $1').trim()
        if (this.$auth.userCan(`READ_${name.replace(/ /g,"_").toUpperCase()}`)) {
          dashboards.push({
            component: componentName,
            name: name
          })
        }
      })

      return dashboards
    }
  },

  watch: {
    selectedDashboard (val) {
      window.localStorage.setItem('dashboard', val)
    }
  },

  mounted () {
    if (window.localStorage.getItem('dashboard')) {
      this.selectedDashboard = window.localStorage.getItem('dashboard')
    } else {
      this.selectedDashboard = 'DefaultDashboard'
    }
  }
}
</script>
