<template>
  <div>
    <v-btn
      v-if="!sundry.ordered_at && !sundry.received_at"
      color="red"
      outlined
      x-small
      depressed
      @click.stop="dialog = true"
    >
      Part Ordered
    </v-btn>
    <div v-else>
      <v-tooltip top>
        <template v-if="sundry.ordered_by" v-slot:activator="{ on }">
          <v-avatar size="20" color="primary" v-on="on">
            <img
              v-if="sundry.ordered_by.profile_img_url"
              :src="sundry.ordered_by.profile_img_url"
              :alt="sundry.ordered_by.name">
            <strong v-else class="white--text" style="font-size: 10px;">{{ sundry.ordered_by.initials }}</strong>
          </v-avatar>
        </template>
        <span>
          Ordered At: {{ $momentDateTimeHuman(sundry.ordered_at) }} | Ordered By: {{ sundry.ordered_by ? sundry.ordered_by.name : null }}
        </span>
      </v-tooltip>
      <span class="ml-1"><small>{{ $momentDateNoYear(sundry.ordered_at) }}</small></span>
    </div>
    <PartOrderedDialog
      :dialog.sync="dialog"
      :sundry="sundry"
    />
  </div>
</template>

<script>
import PartOrderedDialog from '@/components/sundries/PartOrderedDialog'

export default {
  name: 'PartsToOrderOrderedColumn',

  props: {
    sundry: {
      required: true,
      type: Object
    }
  },

  components: {
    PartOrderedDialog
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>