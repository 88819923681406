<template>
  <div>
    <h3>Reports</h3>

    <div class="mt-3">
      <v-btn
        :loading="loading === 'outstanding-payment-links'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('outstanding-payment-links')"
      >
        1. Outstanding Payment Links
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
      <v-btn
        :loading="loading === 'recent-payments'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('recent-payments')"
      >
        2. Recent Payments
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
      <v-btn
        :loading="loading === 'payment-link-reconciliation'"
        color="green"
        class="mr-2 mb-2"
        depressed
        small
        outlined
        @click="getReport('payment-link-reconciliation')"
      >
        3. Payment Link Reconciliation
        <v-icon
          right
          dense
        >
          mdi-file-chart
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'FinanceReportsSummary',

  data () {
    return {
      loading: null
    }
  },

  methods: {
    getReport (type) {
      this.loading = type
      this.$api.persist('post', {
        path: `reports/${type}`
      })
        .then(({ data: report }) => {
          setTimeout(() => {
            window.open(report.temp_url)
            this.loading = null
          }, 2000)
        })
        .catch(() => {
          this.loading = null
        })
    }
  }
}
</script>
