<template>
  <v-row>
    <v-col
      class="py-0 mb-6"
      cols="12"
      md="4"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <UserWelcomeSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Mobile - Booking Metrics Summary -->
        <v-col
          class="d-md-none"
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <TodayBookingMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Mobile - Collect & Return Metrics Summary -->
        <v-col
          class="d-md-none"
          cols="12"
          md="6"
        >
          <v-card outlined>
            <v-card-text>
              <TodayCRMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <ReportsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <ReturnsTodaySummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="py-0 mb-6"
      cols="12"
      md="8"
    >
      <v-row>
        <!-- Desktop - Booking Metrics Summary -->
        <v-col
          class="d-none d-md-block"
          cols="6"
        >
          <v-card outlined>
            <v-card-text>
              <TodayBookingMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Desktop - Collect & Return Metrics Summary -->
        <v-col
          class="d-none d-md-block"
          cols="6"
        >
          <v-card outlined>
            <v-card-text>
              <TodayCRMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <TboMetricsSummary
                :end="$momentDateFormat($moment().add(14, 'days'))"
                 title="To Be Worked On"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <TboMetricsSummary
                :end="$momentDateFormat($moment().add(14, 'days'))"
                title="To Be Worked On (Fleet)"
                fleet
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-card outlined>
            <v-card-text>
              <WeekCollectionMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-card outlined>
            <v-card-text>
              <WeekReturnMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-card outlined>
            <v-card-text>
              <WeekCollectionMetricsSummary next />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-card outlined>
            <v-card-text>
              <WeekReturnMetricsSummary next />
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
import UserWelcomeSummary from '@/components/dashboard/UserWelcomeSummary'
import TodayBookingMetricsSummary from '@/components/dashboard/TodayBookingMetricsSummary'
import TodayCRMetricsSummary from '@/components/dashboard/TodayCRMetricsSummary'
import WeekCollectionMetricsSummary from '@/components/dashboard/WeekCollectionMetricsSummary'
import TboMetricsSummary from '@/components/dashboard/TboMetricsSummary'
import WeekReturnMetricsSummary from '@/components/dashboard/WeekReturnMetricsSummary'
import ReturnsTodaySummary from '@/components/dashboard/ReturnsTodaySummary'
import ReportsSummary from '@/components/dashboard/ReportsSummary'
import IsDashboard from '@/mixins/IsDashboard'

export default {
  name: 'DefaultDashboard',

  components: {
    UserWelcomeSummary,
    TodayBookingMetricsSummary,
    TodayCRMetricsSummary,
    WeekCollectionMetricsSummary,
    WeekReturnMetricsSummary,
    ReturnsTodaySummary,
    ReportsSummary,
    TboMetricsSummary
  },

  mixins: [ IsDashboard ]
}
</script>
