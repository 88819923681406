<template>
  <tr>
    <td>
      <v-avatar size="30" color="primary" class="mt-1 mb-1">
        <img
          v-if="user.profile_img_url"
          :src="user.profile_img_url"
          :alt="user.name">
        <strong v-else class="white--text" style="font-size: 10px;">{{ user.initials }}</strong>
      </v-avatar>
      {{ user.name }}
    </td>
    <!-- Triage -->
    <td>{{ serviceRecordProductivity.TYPE_TRIAGE.count }}</td>

    <!-- BCT -->
    <td>{{ servicesProductivity.bct.count + servicesProductivity.bct_electric.count + servicesProductivity.fix_me_fit_me.count + servicesProductivity.fix_me_fit_me_electric.count }} <small>({{ $currency(servicesProductivity.bct.revenue + servicesProductivity.bct_electric.revenue + servicesProductivity.fix_me_fit_me.revenue + servicesProductivity.fix_me_fit_me_electric.revenue) }})</small></td>

    <!-- Bike Build -->
    <td>{{ servicesProductivity.build_box.count + servicesProductivity.build_box_electric.count }} <small>({{ $currency(servicesProductivity.build_box.revenue + servicesProductivity.build_box_electric.revenue) }})</small></td>

    <!-- Regulare -->
    <td>{{ servicesProductivity.regular.count + servicesProductivity.regular_electric.count }} <small>({{ $currency(servicesProductivity.regular.revenue + servicesProductivity.regular_electric.revenue) }})</small></td>

    <!-- Deluxe -->
    <td>{{ servicesProductivity.deluxe.count + servicesProductivity.deluxe_electric.count }} <small>({{ $currency(servicesProductivity.deluxe.revenue + servicesProductivity.deluxe_electric.revenue) }})</small></td>

    <!-- Super Deluxe -->
    <td>{{ servicesProductivity.super_deluxe.count + servicesProductivity.super_deluxe_electric.count }} <small>({{ $currency(servicesProductivity.super_deluxe.revenue + servicesProductivity.super_deluxe_electric.revenue) }})</small></td>

    <!-- Silver -->
    <td>{{ servicesProductivity.halfords_silver.count + servicesProductivity.halfords_silver_electric.count + servicesProductivity.halfords_silver_retail.count + servicesProductivity.halfords_silver_electric_retail.count + servicesProductivity.halfords_silver_hydraulic_retail.count }} <small>({{ $currency(servicesProductivity.halfords_silver_retail.revenue + servicesProductivity.halfords_silver_electric_retail.revenue + servicesProductivity.halfords_silver_hydraulic_retail.revenue + servicesProductivity.halfords_silver.revenue + servicesProductivity.halfords_silver_electric.revenue) }})</small></td>

    <!-- Gold -->
    <td>{{ servicesProductivity.halfords_gold.count + servicesProductivity.halfords_gold_electric.count + servicesProductivity.halfords_gold_retail.count + servicesProductivity.halfords_gold_electric_retail.count + servicesProductivity.halfords_gold_hydraulic_retail.count }} <small>({{ $currency(servicesProductivity.halfords_gold.revenue + servicesProductivity.halfords_gold_electric.revenue + servicesProductivity.halfords_gold_retail.revenue + servicesProductivity.halfords_gold_electric_retail.revenue + servicesProductivity.halfords_gold_hydraulic_retail.revenue) }})</small></td>

    <!-- Other -->
    <td>{{ servicesProductivity.brompton_firmware.count + servicesProductivity.halfords_total_maintenance_retail.count + servicesProductivity.halfords_repair_and_service_retail.count }} <small>({{ $currency(servicesProductivity.brompton_firmware.revenue + servicesProductivity.halfords_total_maintenance_retail.revenue + servicesProductivity.halfords_repair_and_service_retail.revenue) }})</small></td>

    <!-- QC -->
    <td>{{ serviceRecordProductivity.TYPE_QUALITY_CONTROL.count }}</td>

    <!-- Total Points -->
    <td :class="serviceRecordProductivity.TOTAL_POINTS.color"><strong>{{ serviceRecordProductivity.TOTAL_POINTS.points }}</strong></td>

    <!-- Total Revenue -->
    <td>{{ $currency(serviceRecordProductivity.TOTAL_REVENUE.revenue) }}</td>

    <!-- MTD Total -->
    <td>{{ mtdProductivity.MTD_TOTAL_POINTS }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ProductivityDashboardRow',

  props: {
    user: {
      type: Object,
      required: true
    },
    serviceRecordProductivity: {
      type: Object,
      required: true
    },
    servicesProductivity: {
      type: Object,
      required: true
    },
    mtdProductivity: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      dialog: false
    }
  },

  methods: {
    format(str) {
      if (str) {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        )
      }
      return str
    }
  }
}
</script>
