<template>
  <div>
    <h3>Returns Tomorrow</h3>

    <DataTable
      :cols="cols"
      :request-params="{ with: ['booking'], return_date_on: $moment().add(1, 'd').format('YYYY-MM-DD') }"
      :show-select="false"
      default-order-by="created_at"
      path="jobs"
    >
      <template v-slot:item.ref="{ value, item: bike }">
        <router-link :to="{ name: 'bookings.show', params: { bookingId: bike.booking.id } }">
          {{ value }}
        </router-link>
      </template>
      <template v-slot:item.customer="{ item: bike }">
        {{ bike.booking.customer_name }}
      </template>
    </DataTable>
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'

export default {
  name: 'ReturnsTodaySummary',

  components: {
    DataTable
  },

  data () {
    return {
      cols: [
        { text: 'Ref', value: 'ref' },
        { text: 'Name', value: 'name' },
        { text: 'Customer', value: 'customer' },
        { text: 'Workshop Status', value: 'workshop_status_label' }
      ]
    }
  }
}
</script>
