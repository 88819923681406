<template>
  <div>
    <DataTable
      ref="sundryTable"
      :cols="cols"
      :filter-keys="filterKeys"
      :request-params="{
        with: ['bookingBike.booking.workshopNotes.createdBy', 'createdBy', 'orderedBy', 'receivedBy', 'bookingBike.serviceRecords.endedBy', 'supplier', 'product'
        ],
        createdAfter: '2021-05-05',
        noLabour: true,
        noInStock: true,
        noEstimates: true,
        noDeclined: true
      }"
      default-order-by="triage_created_at"
      path="booking-sundries"
    >
      <template v-slot:item.triage_created_at="{ item: sundry }">
        <div class="py-1">
          <router-link :to="{ name: 'bookings.show', params: { bookingId: sundry.bike.booking_id } }">
            {{ sundry.bike.ref }} - {{ sundry.bike.booking.customer_name }}
          </router-link>
          <div>
            <PartsToOrderTriageColumn :sundry="sundry" />
          </div>
        </div>
      </template>

      <template v-slot:item.product="{ item: sundry }">
        <PartsToOrderPartColumn :sundry="sundry" />
      </template>

      <template v-slot:item.price="{ item: sundry }">
        {{ $currency(sundry.price) }}
      </template>

      <template v-slot:item.supplier="{ item: sundry }">
        <div>
          {{ 
            sundry.supplier_name ? sundry.supplier_name : (sundry.stock_product_data ? sundry.stock_product_data.supplier.name : '-')
          }}
          <a
            v-if="sundry.supplier_id || sundry.stock_product_data"
            :href="`https://telescope.havebike.co.uk/suppliers/${sundry.supplier_id || sundry.stock_product_data.supplier.id}`"
            target="_blank"
            style="text-decoration: none;"
          >
            <v-icon x-small>mdi-open-in-new</v-icon>
          </a>
        </div>
        <div>
          <small><strong>
            {{ sundry.order_po }}
          </strong></small>
        </div>
      </template>

      <template v-slot:item.estimated_delivery_date="{ item: sundry }">
        <PartsToOrderDeliveryColumn :sundry="sundry" />
      </template>
      <template v-slot:item.ordered_at="{ item: sundry }">
        <PartsToOrderOrderedColumn :sundry="sundry" />
      </template>
      <template v-slot:item.received_at="{ item: sundry }">
        <PartsToOrderReceivedColumn
          :sundry="sundry"
          @click="s => { receivedSundry = s; receivedDialog = true }"
        />
      </template>
    </DataTable>

    <PartReceivedDialog
      :dialog.sync="receivedDialog"
      :sundry="receivedSundry"
    />
  </div>
</template>
<script type="text/javascript">
import IsDashboard from '@/mixins/IsDashboard'
import DataTable from '@/components/DataTable'
import PartsToOrderOrderedColumn from '@/components/dashboard/PartsToOrderOrderedColumn'
import PartsToOrderDeliveryColumn from '@/components/dashboard/PartsToOrderDeliveryColumn'
import PartsToOrderTriageColumn from '@/components/dashboard/PartsToOrderTriageColumn'
import PartsToOrderReceivedColumn from '@/components/dashboard/PartsToOrderReceivedColumn'
import PartsToOrderPartColumn from '@/components/dashboard/PartsToOrderPartColumn'
import PartReceivedDialog from '@/components/sundries/PartReceivedDialog'

export default {
  name: 'PartsToOrderDashboard',

  mixins: [ IsDashboard ],

  components: {
    PartReceivedDialog,
    DataTable,
    PartsToOrderOrderedColumn,
    PartsToOrderDeliveryColumn,
    PartsToOrderTriageColumn,
    PartsToOrderReceivedColumn,
    PartsToOrderPartColumn
  },

  mounted () {
    this.getJobTypes()
    const self = this

    if (window.Echo) {
      window.Echo.private('booking-sundries')
        .listen('BookingSundryCreated', () => {
          if (typeof self !== undefined) {
            self.getData()            
          }
        })
        .listen('BookingSundryUpdated', () => {
          if (typeof self !== undefined) {
            self.getData()            
          }
        })
        .listen('BookingSundryDeleted', () => {
          if (typeof self !== undefined) {
            self.getData()            
          }
        })
    }
  },

  data () {
    return {
      jobTypes: [],
      showAddDialog: false,
      receivedDialog: false,
      receivedSundry: {},
      cols: [
        { text: 'Job', value: 'triage_created_at' },
        { text: 'Part', value: 'product' },
        { text: 'Price', value: 'price' },
        { text: 'Supplier', value: 'supplier' },
        { text: 'Ordered', value: 'ordered_at' },
        { text: 'Delivery', value: 'estimated_delivery_date' },
        { text: 'Received', value: 'received_at' },
      ]
    }
  },

  computed: {
    filterKeys () {
      return [
        { key: 'job_number', 'label': 'Job Number', type: 'String' },
        { key: 'job_type', 'label': 'Job Type', type: 'Select', values: this.jobTypes},
        { key: 'supplier', 'label': 'Supplier', type: 'Lookup', lookup: { label: 'Suppliers', url: `${this.$inventory.url}/api/suppliers`, return_value: 'id', return_label: 'name'  } },
        { key: 'estimated_delivery_date', 'label': 'Estimated Delivery', type: 'Date'},
        { key: 'ordered_at', 'label': 'Ordered At', type: 'Date'},
        { key: 'received_at', 'label': 'Received At', type: 'Date'}
      ]
    }
  },

  methods: {
    getData () {
      if (this.$refs?.sundryTable) {
        this.$refs.sundryTable.getData()        
      }
    },

    getJobTypes () {
      this.$api.get({
        path: 'job-types'
      })
        .then(jobTypes => {
          Object.keys(jobTypes).forEach(key => {
            this.jobTypes.push({
              label: jobTypes[key],
              value: key
            })
          })
        })
    }
  }
}
</script>
