var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{
  'red lighten-4 red--text': _vm.job.workshop_status === 'STATUS_TRIAGE_UNABLE_TO_CONTACT',
  'red lighten-5 red--text': _vm.job.workshop_status === 'STATUS_CUSTOMER_CONSIDERING',
  'blue lighten-3 blue--text': _vm.isWorkUnderway,
  'green lighten-2': _vm.job.workshop_status === 'STATUS_TRIAGE_CONFIRMED' || _vm.job.workshop_status === 'STATUS_PARTS_ARRIVED',
  'orange lighten-4 orange--text': _vm.job.workshop_status === 'STATUS_AWAITING_QC',
  'orange lighten-2': _vm.job.workshop_status === 'STATUS_QC_PASSED',
  'orange': _vm.job.workshop_status === 'STATUS_DISPATCHED',
}},[_c('td',[_c('v-chip',{class:{'red lighten-3': _vm.job.job_type === 'JOB_TYPE_C&R_COMPLAINT'},attrs:{"to":{ name: 'bookings.show', params: { bookingId: _vm.job.booking_id } },"x-small":""}},[(_vm.job.job_type === 'JOB_TYPE_C&R_COMPLAINT')?_c('v-icon',{attrs:{"left":"","x-small":"","color":"red darken-2"}},[_vm._v("mdi-exclamation-thick")]):_vm._e(),_vm._v(" "+_vm._s(_vm.job.ref || ' - ')+" "),(_vm.job.asset)?_c('span',{staticClass:"ml-1"},[_vm._v("/ "+_vm._s(_vm.job.asset.number))]):_vm._e()],1)],1),_c('td',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.job.booking.customer_name)+" "),(_vm.fleet && _vm.job.booking.customer.account)?_c('small',[(_vm.job.booking.customer.account.parent)?_c('span',[_vm._v("("+_vm._s(_vm.job.booking.customer.account.name)+") "+_vm._s(_vm.job.booking.customer.account.parent.name))]):_c('span',[_vm._v(_vm._s(_vm.job.booking.customer.account.name))])]):_vm._e()]),_c('td',{staticClass:"font-weight-medium"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.job.location_prefix_label))]),_vm._v(_vm._s(_vm.job.location)+" ")]),_c('td',[_c('WorkshopDashboardServiceRecordCol',{attrs:{"service-record":_vm.triageServiceRecord,"job":_vm.job}})],1),_c('td',[_c('WorkshopDashboardServiceRecordCol',{attrs:{"service-record":_vm.cleanServiceRecord,"job":_vm.job}})],1),_c('td',[_c('WorkshopDashboardServiceRecordCol',{attrs:{"service-record":_vm.triageConfirmationServiceRecord,"job":_vm.job}})],1),_c('td',[_c('WorkshopDashboardServiceRecordCol',{attrs:{"service-record":_vm.serviceRecord,"job":_vm.job,"timer":_vm.job.workshop_status === 'STATUS_WORK_UNDERWAY'}})],1),_c('td',[_c('WorkshopDashboardServiceRecordCol',{attrs:{"service-record":_vm.qualityControlServiceRecord,"job":_vm.job}})],1),_c('td',[(_vm.job.booking.workshop_notes.length > 0)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","x-small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.job.booking.workshop_notes.length)+" Note"+_vm._s(_vm.job.booking.workshop_notes.length > 1 ? 's' : '')+" ")])]}}],null,false,2234029400),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Notes For "+_vm._s(_vm.job.ref)+" ")]),_c('v-card-text',{staticClass:"d-flex flex-column pt-4"},_vm._l((_vm.job.booking.workshop_notes),function(note){return _c('div',{key:note.id,staticClass:"w-full my-2"},[_c('NoteBox',{attrs:{"data":note},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","small":"","dense":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$momentDateTimeHuman(note.created_at))+" by "+_vm._s(note.created_by.name)+" "),_c('v-avatar',{attrs:{"size":"20","color":"primary"}},[_c('img',{attrs:{"src":note.created_by.profile_img_url,"alt":note.created_by.name}})])],1)])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(note.content)+" ")])],1)}),0),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()],1),_c('td',{staticClass:"font-weight-medium"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.job.manafacturer)?_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"x-small":""}},on),[_vm._v(" "+_vm._s(_vm.format(_vm.job.manafacturer))+" ")]):_vm._e()]}}])},[_c('span',[_vm._v("Manafacturer")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.job.model)?_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"x-small":""}},on),[_vm._v(" "+_vm._s(_vm.format(_vm.job.model))+" ")]):_vm._e()]}}])},[_c('span',[_vm._v("Model")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.job.colour)?_c('v-chip',_vm._g({staticClass:"mr-1",attrs:{"x-small":""}},on),[_vm._v(" "+_vm._s(_vm.format(_vm.job.colour))+" ")]):_vm._e()]}}])},[_c('span',[_vm._v("Colour")])])],1),_c('td',{staticClass:"font-weight-medium"},[(_vm.job.is_complaint)?_c('span',[_vm._v(" Complaint: "),_c('strong',[_vm._v(_vm._s(_vm.job.complaint_category_label))])]):_c('span',[_vm._v(_vm._s(_vm.job.service.name))])]),_c('td',{staticClass:"font-weight-medium"},[(_vm.job.rearranged_return_date)?_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$momentDate(_vm.job.rearranged_return_date)))]):_c('span',[_vm._v(_vm._s(_vm.$momentDate(_vm.job.booking.return_date)))])]),(!_vm.fleet)?_c('td',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$currency(_vm.job.sundries_total))+" / "+_vm._s(_vm.$currency(_vm.job.total + _vm.job.sundries_total)))]):_vm._e(),_c('td',[_c('v-chip',{staticClass:"font-weight-medium",attrs:{"x-small":"","color":_vm.job.workshop_status === 'STATUS_CANCELLED' ? 'red' :
        _vm.job.workshop_status === 'STATUS_QC_PASSED' ? 'orange' :
        ''}},[_vm._v(" "+_vm._s(_vm.job.workshop_status_label)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }