<template>
  <div>
    <canvas style="height: 250px;" ref="monthCollections"></canvas>
  </div>
</template>
<script type="text/javascript">
import HasMetrics from '@/mixins/HasMetrics'
import HasLineGraph from '@/mixins/HasLineGraph'
import Chart from 'chart.js'

export default {
  name: 'MtdJobMetricsSummary',

  mixins: [ HasMetrics, HasLineGraph ],

  props: {
    next: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      title: 'MTD TBO'
    }
  },

  methods: {
    mountChart () {
      new Chart(this.$refs.monthCollections, this.chartConfig)
    },

    getMetrics () {
      this.$api.get({
        path: 'finance/metrics/jobs'
      })
      .then(({ data: { mtd_summary } }) => {
        this.metrics = mtd_summary
        this.mountChart()
      })
    }
  }
}
</script>
