<template>
  <v-row>
    <v-col
      class="py-0 mb-6"
      cols="12"
      md="4"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- MTD Cumulative Revenue -->
              <MonthJobsMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- MTD Revenue -->
              <MtdFinanceMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- MTD TBO -->
              <MtdJobMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- Reports -->
              <FinanceReportsSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="py-0 mb-6"
      cols="12"
      md="8"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- MTD Revenue + MTD Bike + MTD ARPB -->
              <MtdSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- Revenue Earned Today -->
              <IncomeSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- WTD Revenue -->
              <WtdSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <!-- WTD TBO -->
              <WtdFinanceMetricsSummary />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script type="text/javascript">
// import PaymentLinksOutstandingSummary from '@/components/dashboard/PaymentLinksOutstandingSummary'
import IncomeSummary from '@/components/dashboard/IncomeSummary'
import FinanceReportsSummary from '@/components/dashboard/FinanceReportsSummary'
import MtdFinanceMetricsSummary from '@/components/dashboard/MtdFinanceMetricsSummary'
import MonthJobsMetricsSummary from '@/components/dashboard/MonthJobsMetricsSummary'
import WtdFinanceMetricsSummary from '@/components/dashboard/WtdFinanceMetricsSummary'
import MtdJobMetricsSummary from '@/components/dashboard/MtdJobMetricsSummary'
import MtdSummary from '@/components/dashboard/MtdSummary'
import WtdSummary from '@/components/dashboard/WtdSummary'
import IsDashboard from '@/mixins/IsDashboard'

export default {
  name: 'FinanceDashboard',

  components: {
    // PaymentLinksOutstandingSummary,
    IncomeSummary,
    FinanceReportsSummary,
    MtdFinanceMetricsSummary,
    MonthJobsMetricsSummary,
    MtdJobMetricsSummary,
    MtdSummary,
    WtdFinanceMetricsSummary,
    WtdSummary,
  },

  mixins: [ IsDashboard ]
}
</script>
