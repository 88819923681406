<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Update Estimated Delivery</span>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col :cols="12" :sm="6">
                <v-dialog
                  ref="expectedDeliveryDate"
                  v-model="expectedDeliveryDateDialog"
                  :return-value.sync="form.estimated_delivery_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="form.estimated_delivery_date ? $moment(form.estimated_delivery_date).format('ddd Do MMM') : null"
                      label="Expected Delivery Date"
                      outlined
                      dense
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.estimated_delivery_date"
                    :min="$moment().format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="expectedDeliveryDateDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.expectedDeliveryDate.save(form.estimated_delivery_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-divider class="mb-4" />
            <v-row>
              <v-col :cols="12" :sm="6">
                <v-checkbox
                  v-model="form.send_expected_delivery_date_notification"
                  label="Send Expected Delivery Notification?"
                />
              </v-col>
            </v-row>
            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn color="primary" :loading="loading" text @click="updateSundry">Update Estimated Delivery <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar success ref="successBar">
      Part updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the part.
    </SnackBar>
  </div>
</template>

<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {

  name: 'PartEstimatedDeliveryDialog',

  mixins: [ HasDialog, HasForm ],

  props: {
    sundry: {
      required: true,
      type: Object
    }
  },

  components: {
    FormErrors,
    SnackBar
  },

  data () {
    return {
      loading: false,
      expectedDeliveryDateDialog: false
    }
  },

  watch: {
    dialog (dialog) {
      this.form = {
        ...this.form,
        ...this.sundry
      }
      if (dialog) {
        this.form = {
          ...this.form,
          ...{
            send_expected_delivery_date_notification: true
          }
        }
      }

      if (this.sundry.estimated_delivery_date) {
        this.$set(this.form, 'estimated_delivery_date', this.$moment(this.sundry.estimated_delivery_date).format('YYYY-MM-DD'))
      }
    }
  },

  methods: {
    updateSundry () {
      this.$refs.formErrors.clear()

      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('put', {
            path: `bookings/${this.sundry.bike.booking_id}/sundries/${this.sundry.id}/delivery-updated`,
            object: {
              send_expected_delivery_date_notification: this.form.send_expected_delivery_date_notification || false,
              estimated_delivery_date: this.form.estimated_delivery_date
            }
          })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>